import React, { useState, useEffect } from "react";
import "../Homepage/home.scss";

import heroimg from "../../Assests/Images/hero-light.png";
import wpimg from "../../Assests/Icon/whatsapp.svg";
import listicon from "../../Assests/Icon/list-icon.svg";
import indimg from "../../Assests/Images/industry1.png";
import arrow from "../../Assests/Icon/arrow.svg";
import calender from "../../Assests/Icon/calendar.svg";
import Lines from "../GridLines/Lines";
import axios from "axios";
import { Link } from "react-router-dom";

function Home() {
  const [plant, setPlant] = useState();
  const [event, setEvent] = useState();
  const arrayData = [];
  const eventData = [];
  const firstEvent = [];
  let text_time = [];
  useEffect(() => {
    const height = document.documentElement.scrollHeight;
    const value = (height / 100) * 1;
    document.documentElement.style.setProperty("--home-s", `${value}s`);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}apiplants.php`)
      .then((res) => {
        Object.entries(res.data.data).map((el) => {
          arrayData.push(el);
        });
        setPlant(arrayData);
      })
      .catch(() => { });
  }, []);
  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}apievents.php`)
      .then((res) => {
        if (res.status === 200) {
          Object.entries(res.data.data).map((arr) => {
            eventData.push(arr[1]);
          });
          firstEvent.push(eventData[0])
          setEvent(firstEvent);
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  }, []);
  return (
    <>
      <div className="home-main">
        <div className="wp-link">
          <a
            href="https://wa.me/+917487972107"
            target="_blank"
            rel="noopener noreferrer"
            className="d-inline-block text-decoration-none"
          >
            <img
              src={wpimg}
              alt="Whatsapp Icon"
              width={60}
              height={60}
              className="wp"
            ></img>
          </a>
          <div className="circle-waves"></div>
          <div className="circle_waves"></div>
          <div className="circle-waves"></div>
          <div className="circle_waves"></div>
        </div>

        {/***************  hero section ******************/}

        <section className="hero-section">
          <div className="container">
            <Lines />

            <div className="hero-start">
              <div className="hero-content d-flex">
                <div className="hero-left">
                  <div className="hero-heading">
                    <h1 className="head-text o600-w fs-55 lh-84">
                      <span className="d-block">
                        <span className="clr-diff">Brighter Moments</span>
                        <span> on</span>
                      </span>
                      <span className="d-block">a Darker Night</span>
                    </h1>
                    <p className="hero-desc pn-w fs-16 lh-28 text-white opacity-75">
                      A Company by a young entrepreneur of young India. We are
                      the leading manufacturing industries and our proud brands
                      are A-one and Q-Lux are Part of That. We having the 18
                      years of Experience in LED products.
                    </p>
                    <a href="#" className="exploremore">
                      Explore More
                    </a>
                  </div>
                  <div className="site-stats">
                    <div className="coustomer">
                      <h2 className="stat-head o500-w fs-32 lh-40">125K+</h2>
                      <span className="stat-desc d-block pn-w text-white opacity-75 fs-16 lh-28">
                        Satisfied Customers
                      </span>
                    </div>
                    <div className="manufacturing">
                      <h2 className="stat-head o500-w fs-32 lh-40">100% </h2>
                      <span className="stat-desc d-block pn-w text-white opacity-75 fs-16 lh-28">
                        Indian Manufactring
                      </span>
                    </div>
                    <div className="product-h">
                      <h2 className="stat-head o500-w fs-32 lh-40">18Year+</h2>
                      <span className="stat-desc d-block pn-w text-white opacity-75 fs-16 lh-28">
                        In Led Products
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero-right">
            <img src={heroimg} className="herolight" alt="Hero Section"></img>
          </div>
        </section>

        <hr className="sec-line"></hr>

        {/* ************************ industries youtube video section *******************/}

        <section className="industries-yt">
          <div className="container-fluid p-0">
            <div className="container">
              <div className="industries-content">
                <div className="industries-heading">
                  <h2 className="ind-head o600-w fs-50 lh-74 ">
                    <span className="d-block">
                      Welcome To Dudhat Industries
                    </span>
                    <span className="d-block"> Pvt. Ltd.</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="yt-vdo">
              {/* <iframe width="560"  height="315" src="https://www.youtube.com/embed/I9rWJlIHGIo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
              <iframe width="560" height="315" src="https://www.youtube.com/embed/6nWAErOu1PI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className="container">
              <div className="yt-desc-div">
                <div className="list-icon-div">
                  <img src={listicon} alt="list icon"></img>
                </div>
                <p className="yt-desc pn-w fs-16 lh-28 text-white opacity-75">
                  A Company by a young entrepreneur of young India. We are the
                  leading manufacturing industries and our proud brands are
                  A-one and Q-Lux are Part of That. We having the 18 years of
                  Experience in LED products. Our one of the Director started
                  this Journey before 18 years and now we stand at 2020 at the
                  different level. We have faced many bumps while this Journey
                  but we take lessons from that and go ahead on way.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/**************  about-industries *******************************/}

        <section className="about-industries">
          <div className="container">
            <div className="about-content">
              <div className="about-heading-div">
                <h2 className="o600-w fs-50 lh-74">
                  <span className="highlight">We are different</span>
                  <span> from other led lights</span>
                  <span> manufacturing because</span>
                  <span className="highlight"> we have all facility </span>
                  <span>that need to making</span>
                  <span className="highlight"> led products.</span>
                </h2>
              </div>
              <div className="about-details justify-content-between">
                <div className="about-details-left d-flex">
                  <div className="list-icon me-3">
                    <img src={listicon} alt="list icon"></img>
                  </div>
                  <div className="industries-desc-div">
                    <p className="industries-desc pn-w text-white opacity-75 fs-16 lh-28">
                      We can deliver the products on time and Develop any new
                      product within months in which as compare time some
                      companies imports from other countries. So our major
                      strength is all process in-house so we can make new
                      products within very short time and with quality
                      assurance.
                    </p>
                  </div>
                </div>
                <div className="about-details-right d-flex">
                  <div className="list-icon me-3">
                    <img src={listicon} alt="list icon"></img>
                  </div>
                  <div className="industries-desc-div">
                    <p className="industries-desc pn-w text-white opacity-75 fs-16 lh-28">
                      We are the Manufacturing company for LED at innovative
                      level so We having tool room in-house for any new product
                      Development. We are having in house plastic moulding
                      machines , plastic Extrusion line for plastic products ,
                      aluminium die casting plant , Powder coating plants
                    </p>
                  </div>
                </div>
              </div>
              <div className="ind-img mb-3">
                <img src={indimg} alt="Event " className="img-fluid"></img>
              </div>
              <div className="about-details justify-content-between">
                <div className="about-details-left d-flex">
                  <div className="list-icon me-3">
                    <img src={listicon} alt="list icon"></img>
                  </div>
                  <div className="industries-desc-div">
                    <p className="industries-desc pn-w text-white opacity-75 fs-16 lh-28">
                      We are having in house PCB manufacturing plant for any
                      kind of LED PCB and other types PCB.This plant is fully
                      automatic and have capacity of 30k sheets a month We have
                      in-house pick and place machines for LED mountings and we
                      have also driver manufacturing unit for different drivers.
                    </p>
                  </div>
                </div>
                <div className="about-details-right d-flex">
                  <div className="list-icon me-3">
                    <img src={listicon} alt="list icon"></img>
                  </div>
                  <div className="industries-desc-div">
                    <p className="industries-desc pn-w text-white opacity-75 fs-16 lh-28">
                      We have testing labs also for different types of test of
                      lights Like surge test , high voltage and low voltage test
                      , lumens testing , humidity test and efficiency of product
                      test etc. So the Dudhat industries is the first choice for
                      any big OEM clients to take their brands value at next
                      level.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <hr className="sec-line"></hr>

        {/* ******************* section-events ******************* */}

        <section className="section-events">
          <div className="container">
            <div className="events-wrapper">
              <div className="events-content">
                <div className="event-desc-div">
                  <h3 className="event-desc-head o600-w fs-50 lh-74">
                    Our Working Plants
                  </h3>
                  <p className="event-desc pn-w text-white opacity-75 fs-16 lh-28">
                    We do OEM for many brands and adding their brand value by
                    adding our experience of manufacturing and make their brand
                    at next level.
                  </p>
                </div>
                {plant?.length > 0 &&
                  plant.map((val, index) => {
                    console.log("valueeeeeee", val);
                    return (
                      <>
                        <div
                          className={
                            index + 1 == 1
                              ? "mt-0 plant-wrapper"
                              : "mt-100 plant-wrapper-unset"
                          }
                        >
                          <div
                            className={
                              index + 1 == 1
                                ? " first"
                                : index % 2 == 0
                                  ? "plant-box-right"
                                  : "plant-box-left"
                            }
                          >
                            <div className="event-img-div">
                              <img
                                src={
                                  `${process.env.REACT_APP_BASE_URL}` +
                                  val[1].pic
                                }
                                alt="Plant display here"
                                className="plant1"
                                height={390}
                                width={520}
                              ></img>
                              <div className="bottom-blur">
                                <span className="plant-name o600-w fs-20 lh-25 d-inline-block">
                                  {val[1].name}
                                </span>
                                <Link to={`/plantdetails/${val[1].id}`}>
                                  View Details
                                  <img
                                    src={arrow}
                                    className="ms-2"
                                    alt="arrow icon"
                                    height={22}
                                    width={22}
                                  ></img>
                                </Link>
                              </div>
                            </div>
                            <div className="event2-text">
                              <p className="event-desc pn-w text-white opacity-75 fs-16 lh-28">
                                {val[1].description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>

        <hr className="sec-line"></hr>

        {/* **************************** Latest Events *************************** */}

        <section className="latest-events">
          <div className="container">
            <div className="latest-content">
              <div className="row lt-first">
                <div className="col-6">
                  <h2 className="lts-heading o600-w fs-50 lh-74">
                    Latest Events
                  </h2>
                </div>
                <div className="col-6">
                  <p className="lts-desc pn-w text-white opacity-75 fs-16 lh-28">
                    The Dudhat LED lighting Show is slated for 7-9 may to the
                    Edition 2020 at the Mumbai.the show is recognizable for new
                    products Lonching of LED lighting. The event seeks to
                    exhibit the most advanced technologies and solutions to make
                    life easier.the Dudhat lighting industry has progressed
                    beyond imagination
                  </p>
                </div>
              </div>
              <div className="main-event">
                {event?.length > 0 &&
                  event.map((value) => {
                    text_time = value.created_at;
                    const spliting_time = text_time.split(" ");
                    console.log("val", value);
                    return (
                      <>
                        <div className="mainevent-img">
                          <img
                            src={
                              `${process.env.REACT_APP_BASE_URL}` + value.pic
                            }
                            alt="Event"
                            className="img-fluid maineventimg"
                            width="100%"
                          ></img>
                          <div className="bottom-blur">
                            <div className="event1-left">
                              <span>{value.name}</span>
                              <a
                                href="#"
                                className="date d-flex align-items-center"
                              >
                                <img
                                  src={calender}
                                  alt="Calender Icon"
                                  className="cal me-3"
                                  height={20}
                                  width={20}
                                ></img>
                                {spliting_time[0]}
                              </a>
                            </div>
                            <div className="event1-right">
                              <Link to={`/eventdetails/${value.id}`}>
                                View Details
                                <img
                                  src={arrow}
                                  className="ms-2"
                                  alt="arrow icon"
                                  height={22}
                                  width={22}
                                ></img>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Home;
