import React, { useState, useEffect } from "react";
import logowhite from "../../Assests/Icon/logo-white.png";
import "../../Components/Header/header.scss";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import arrowDD from "../../Assests/Icon/Arrow.png";
import SubProduct from "../../Components/SubProduct";
import arrowhite from "../../Assests/Icon/arrow-white.svg";

function Header() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [responsiveDD, setresponisveDD] = useState(false);

  const [data, setData] = useState([]);
  let arrayData = [];
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}apicategories.php`)
      .then((res) => {
        Object.entries(res.data.data.categories).map((el) => {
          arrayData.push(el[1]);
        });
        setData(arrayData);
        console.log(arrayData);
      });
  }, []);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 1200) {
      setDropdown(false);
      setresponisveDD(true);
    } else {
      setDropdown(true);
      setresponisveDD(false);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 1200) {
      setDropdown(false);
      setresponisveDD(false);
    } else {
      setDropdown(false);
      setresponisveDD(false);
    }
  };

  return (
    <header>
      <div className="container">
        <nav className="navbar">
          <Link to="/home" className="navbar-brand" onClick={closeMobileMenu}>
            <img
              src={logowhite}
              className="logoicon d-block"
              alt="Logo Icon"
              height={55}
              width={110}
            ></img>
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item" onClick={closeMobileMenu}>
              <Link to="/home" className="nav-link" aria-current="page">
                Home
              </Link>
            </li>
            <li
              className="nav-item"
              onClick={closeMobileMenu}
              onMouseEnter={() => setDropdown(false)}
            >
              <Link to="/aboutus" className="nav-link">
                About us
              </Link>
            </li>
            <li className="nav-item" id="pr-hover" onMouseEnter={onMouseEnter}>
              {responsiveDD ? (
                <div class="nav1" onMouseLeave={onMouseLeave}>
                  <input type="checkbox" id="menu" />
                  <label for="menu" id="nav-icon">
                    Products
                  </label>

                  <div class="multi-level">
                    {/* <a href="#" className="aone-product">
                      Aone Products
                      <img
                        src={arrowhite}
                        alt="Arrow Icon"
                        className="arrowicon ms-2"
                        width={28}
                        height={20}
                      ></img>
                    </a> */}
                    <a href="http://qluxled.com/" className="qlux-product">
                      Qlux Products
                      <img
                        src={arrowhite}
                        alt="Arrow Icon"
                        className="arrowicon ms-2"
                        width={28}
                        height={20}
                      ></img>
                    </a>
                    {data?.length > 0 &&
                      data.map((values) => {
                        return (
                          <>
                            <div class="item">
                              <input type="checkbox" id={values.category_id} />
                              <img src={arrowDD} class="arrow1" />
                              <label for={values.category_id}>
                                {values.category_name}
                              </label>
                              {values.subcategory &&
                                Object.entries(values.subcategory).map(
                                  (item) => {
                                    return (
                                      <>
                                        <ul>
                                          <li onClick={closeMobileMenu}>
                                            <Link
                                              to={`/products/${item[1]["subcategory_id"]}`}
                                              className="text-decoration-none pn-w fs-14 lh-21 text-white"
                                            >
                                              {item[1]["subcategory_name"]}
                                            </Link>
                                          </li>
                                        </ul>
                                      </>
                                    );
                                  }
                                )}
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              ) : (
                <a href="#" id="product_open" className="nav-link">
                  Products
                </a>
              )}
            </li>
            <li
              className="nav-item"
              onClick={closeMobileMenu}
              onMouseEnter={() => setDropdown(false)}
            >
              <Link to="/investers" className="nav-link">
                Investers
              </Link>
            </li>
            <li
              className="nav-item"
              onClick={closeMobileMenu}
              onMouseEnter={() => setDropdown(false)}
            >
              <Link to="/ourbrand" className="nav-link">
                Our Brand
              </Link>
            </li>
            <li
              className="nav-item"
              onClick={closeMobileMenu}
              onMouseEnter={() => setDropdown(false)}
            >
              <Link to="/events" className="nav-link">
                Events
              </Link>
            </li>
            <li
              className="nav-item"
              onClick={closeMobileMenu}
              onMouseEnter={() => setDropdown(false)}
            >
              <Link to="/download" className="nav-link"
              >
                Download
              </Link>
            </li>
            <li
              className="nav-item"
              onClick={closeMobileMenu}
              onMouseEnter={() => setDropdown(false)}
            >
              <Link to="/achievements" className="nav-link">
                Achievements
              </Link>
            </li>
            <li
              className="nav-item"
              onClick={closeMobileMenu}
              onMouseEnter={() => setDropdown(false)}
            >
              <Link to="/career" className="nav-link">
                Career
              </Link>
            </li>
            <li
              className="nav-item"
              onClick={closeMobileMenu}
              onMouseEnter={() => setDropdown(false)}
            >
              <Link to="/contact" className="nav-link">
                Contact
              </Link>
            </li>
            <li
              className="inquery-res"
              onClick={closeMobileMenu}
              onMouseEnter={() => setDropdown(false)}
            >
              <Link to="/inquery" href="#" className="inq-res">
                Inquiry
              </Link>
            </li>
          </ul>
          <div
            className="inquery"
            onClick={closeMobileMenu}
            onMouseEnter={() => setDropdown(false)}
          >
            <Link to="/inquery" href="#" className="inq">
              Inquiry
            </Link>
          </div>
        </nav>
      </div>
      {dropdown && <SubProduct setDropdown={setDropdown} dropdown={dropdown} />}
    </header>
  );
}

export default Header;
