import React from "react";
import "../../Components/Footer/footer.scss";
import ghostimg from "../../Assests/Icon/gehost.svg";
import locimg from "../../Assests/Icon/location.svg";
import phone from "../../Assests/Icon/calling.svg";
import msg from "../../Assests/Icon/message.svg";
import logowhite from '../../Assests/Icon/logo-white.png'

function Footer() {
  return (
    <footer>
      <div className="container-fluid p-0">
        <div className="container">
          <div className="first-row  justify-content-between d-flex">
            <div className="gehost">
             <a href="#" className="d-block"> <img
                src={logowhite}
                className="hostimg d-block"
                alt="Ge Host Icon"
                width={145}
                height={65}
              ></img></a>
              <p className="ghost-desc">
                A Company by a young entrepreneur of young India. We are the
                leading manufacturing industries and our proud brands are A-one
                and Q-Lux are Part of That. We having the 18 years of Experience
                in LED products.
              </p>
            </div>
            <div className="company">
              <ul className="list-unstyled">
                <li>
                  <h3 className="fl-heading">Company</h3>
                </li>
                <li className="footer-link">
                  <a href="#">About Dudhat Industries</a>
                </li>
                <li className="footer-link">
                  <a href="#">Awards & Reviews</a>
                </li>
                <li className="footer-link">
                  <a href="#">Who We Are</a>
                </li>
                <li className="footer-link">
                  <a href="#">Press & Media</a>
                </li>
              </ul>
            </div>
            <div className="product">
              <ul className="list-unstyled">
                <li>
                  <h3 className="fl-heading">Products</h3>
                </li>
                <li className="footer-link">
                  <a href="#">Shared Hosting</a>
                </li>
                <li className="footer-link">
                  <a href="#">Reseller Hosting</a>
                </li>
                <li className="footer-link">
                  <a href="#">Dedicated Server</a>
                </li>
                <li className="footer-link">
                  <a href="#">Application Hosting</a>
                </li>
              </ul>
            </div>
            {/* <div className="sitemap">
              <ul className="list-unstyled">
                <li>
                  <h3 className="fl-heading">Site map</h3>
                </li>
                <li className="footer-link">
                  <a href="#">Hot Deals</a>
                </li>
                <li className="footer-link">
                  <a href="#">Affiliate Sign Up</a>
                </li>
                <li className="footer-link">
                  <a href="#">Affiliate Login</a>
                </li>
                <li className="footer-link">
                  <a href="#">Compare Plans</a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
        <hr className="sec-line"></hr>
        <div className="container">
          <div className="second-row ">
            <div className="reach-us">
              <h3 className="fl-heading">Reach Us</h3>
              <div className="d-flex location-div">
                <img
                  src={locimg}
                  className="locicon"
                  alt="Location Icon"
                  width={20}
                  height={25}
                ></img>
                <p className="loc-address">
                  Plot No.- 218, Rudra Industrial Park,B/H NK Estate,Dhamtvan
                  Road,Bakrol Bujrang - Bakrol, Ahmedabad-382433, Gujarat
                  (India).
                </p>
              </div>
            </div>
            <div className="social-wrapper">
              <div className="contact-us">
                <h3 className="fl-heading">Contact Us</h3>
                <a href="#" className="d-block contactdial">
                  <img
                    src={phone}
                    className="phone me-3"
                    alt="calling icon"
                    width={26}
                    height={26}
                  ></img>
                  1800 121 2292
                </a>
                <a href="#" className="d-block msg">
                  <img
                    src={msg}
                    className="msgicon me-3"
                    height={25}
                    width={25}
                    alt="Message Icon"
                  ></img>
                  info@dudhatindustries.com
                </a>
              </div>
              <div className="followus">
                <h3 className="fl-heading">Follow us</h3>
                <div className="social-icon">
                  <a href="https://mobile.twitter.com/aonegold03" className="twit">
                    <i class="fab fa-twitter"></i>
                  </a>
                  <a href="#" className="yt">
                    <i class="fab fa-youtube"></i>
                  </a>
                  <a href="https://www.instagram.com/" className="insta">
                    <i class="fab fa-instagram-square"></i>
                  </a>
                  <a href="https://www.linkedin.com/m/company/dudhat-industries-pvt-ltd/" className="ln">
                    <i class="fab fa-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="sec-line"></hr>
        <div className="container">
          <div className="copyright d-flex justify-content-between align-items-center">
            <div className="cpy">
              Copyright © 1999 - 2021 Dudhat Industries LLC. All Rights
              Reserved.
            </div>
            <div className="pages">
              <a href="#" className="me-4">Legal</a>
              <a href="#" className="me-4">Privacy Policy</a>
              <a href="#" className="me-4">Advertising Preferences</a>
              <a href="#">Cookies</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
