import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import "../Components/subproduct.scss";
import arrowhite from "../Assests/Icon/arrow-white.svg";
function SubProduct({ setDropdown, dropdown }) {
  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };
  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };
  const [data, setData] = useState([]);
  // const { product_id, category_id, subcategory_id } = useParams();
  let arrayData = [];
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}apicategories.php`)
      .then((res) => {
        Object.entries(res.data.data.categories).map((el) => {
          arrayData.push(el[1]);
        });
        setData(arrayData);
        console.log(arrayData);
      });
  }, []);
  return (
    <>
      <div
        className="container-fluid h "
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="container">
          <div className="inner-wrapper">
            <div className="subproduct-row mx-0">
              <div className="subpoduct-head-items me-50 p-0">
                <a href="http://qluxled.com/" className="qlux-product">
                  Qlux Products
                  <img
                    src={arrowhite}
                    alt="Arrow Icon"
                    className="arrowicon ms-2"
                    width={28}
                    height={20}
                  ></img>
                </a>
              </div>
              <div className="products-lists">
              {data?.length > 0 &&
                data.map((values) => {
                  return (
                    <>
                        <div className="sub-sub-items p-0">
                          <ul className="list-unstyled">
                            <li className="mb-4">
                              <span className="d-inline-block pn-w fs-14 lh-21 text-white opacity-75">
                                {values.category_name}
                              </span>
                            </li>
                            <li className="mb-2">
                              <a
                                href="#"
                                className="text-decoration-none pn-w fs-14 lh-21 text-white"
                              >
                                {values.subcategory &&
                                  Object.entries(values.subcategory).map(
                                    (item) => {
                                      return (
                                        <>
                                          {
                                            <li className="mb-2">
                                              <Link
                                                to={`/products/${item[1]["subcategory_id"]}`}
                                                className="text-decoration-none pn-w fs-14 lh-21 text-white"
                                              >
                                                {item[1]["subcategory_name"]}
                                              </Link>
                                            </li>
                                          }
                                        </>
                                      );
                                    }
                                  )}
                              </a>
                            </li>
                          </ul>
                      </div>
                    </>
                  );
                })}
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubProduct;
