import ReactDOM from "react-dom";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Homepage/Home";
import React, { Suspense } from "react";

const PlantDetails = React.lazy(() => import("./Pages/Plant-Details/PlantDetails"));
const EventDetails = React.lazy(() => import("./Pages/Event-Details/EventDetails"));
const Inquery = React.lazy(() => import("./Pages/Inquery/Inquery"));
const CareerInner = React.lazy(() =>
  import("./Pages/Career-Inner/CareerInner")
);
const CareerSales = React.lazy(() =>
  import("./Pages/Career-Sales/CareerSales")
);
const Event = React.lazy(() => import("./Pages/Events/Event"));
const Investers = React.lazy(() => import("./Pages/Invester/Invester"));
const Achievements = React.lazy(() =>
  import("./Pages/Achievements/Achievements")
);
const OurBrand = React.lazy(() => import("./Pages/OurBrand/OurBrand"));
const Contactus = React.lazy(() => import("./Pages/ContactUs/ContactUs"));
const Product1 = React.lazy(() => import("./Pages/Products/Product1"));
const ProductDetail = React.lazy(() =>
  import("./Pages/Product-Detail/ProductDetail")
);
const About = React.lazy(() => import("./Pages/About/About"));
const Career = React.lazy(() => import("./Pages/Career/Career"));
const Error = React.lazy(() => import("./Components/Error.js"));
const Download = React.lazy(() => import("./Pages/Download/Download.js"));


export default function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/products/:subcategory_id" element={<Product1 />} />
          <Route path="/events" element={<Event />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact" element={<Contactus />} />
          <Route path="/inquery" element={<Inquery />} />
          <Route path="/investers" element={<Investers />} />
          <Route path="/ourbrand" element={<OurBrand />} />
          <Route path="/achievements" element={<Achievements />} />
          <Route
            path="/products/productdetail/:product_id/:category_id/:subcategory_id"
            element={<ProductDetail />}
          />
          <Route path="/plantdetails/:id" element={<PlantDetails />} />
          <Route path="/eventdetails/:id" element={<EventDetails />} />
          <Route path="/career/readmore/:opening_id" element={<CareerSales />} />
          <Route path="/career/readmore/applynow" element={<CareerInner />} />
          <Route element={<Error />}></Route>
          <Route path="/download" element={<Download />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </Suspense>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
