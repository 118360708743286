import React from "react";
import "../GridLines/lines.scss";
function Lines(props) {
  
  return (
    <div className="lines">
      <div className="wrapper">
        <div className="line" ></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  );
}

export default Lines;
